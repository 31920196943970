export const slug = {
  login: 'login',
  passwordReset: 'password-reset',
  registration: 'registration',
  reservation: 'reservation',
  id: ':id',
};

export const path = {
  reservationPage: '/',
  loginPage: `/${slug.login}`,
  passwordResetPage: `/${slug.passwordReset}`,
  registrationPage: `/${slug.registration}`,
  reservationsPage: `/${slug.reservation}`,
};
