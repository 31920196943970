import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { App } from 'app';
import { AppConfig } from 'constants/app-config';
import { slug } from 'constants/path';

const ReservationPage = React.lazy(
  () => import('pages/reservation-page/reservation-page')
);
const ReservationsPage = React.lazy(
  () => import('pages/reservations-page/reservations-page')
);
const LoginPage = React.lazy(() => import('pages/login-page/login-page'));
const RegistrationPage = React.lazy(
  () => import('pages/registration-page/registration-page')
);
const PasswordResetPage = React.lazy(
  () => import('pages/password-reset-page/password-reset-page')
);

export const appRouter = createBrowserRouter(
  [
    {
      element: <App />,
      children: [
        { index: true, element: <ReservationPage /> },
        { path: slug.login, element: <LoginPage /> },
        { path: slug.passwordReset, element: <PasswordResetPage /> },
        {
          path: slug.registration,
          element: <RegistrationPage />,
        },
        { path: slug.reservation, element: <ReservationsPage /> },
      ],
    },
  ],
  {
    basename: AppConfig.publicUrl,
  }
);
